var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-actions',{ref:"formCard",attrs:{"title":"Edit Kelas","no-actions":""}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('validation-observer',{ref:"formRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Skill","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Skill","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.skillItems,"label":"title","searchable":false,"state":errors.length > 0 ? false:null},model:{value:(_vm.skill),callback:function ($$v) {_vm.skill=$$v},expression:"skill"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Cover Kelas","label-cols-md":"4"}},[_c('b-form-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":"Cover Kelas"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":_vm.imageInitialFile ?
                          _vm.imageInitialFile.split('/').pop()
                          : "Pilih atau drop file disini...","drop-placeholder":"Drop file disini...","accept":"image/*"},model:{value:(_vm.imageFile),callback:function ($$v) {_vm.imageFile=$$v},expression:"imageFile"}}),(_vm.imageFile !== null)?_c('div',[(_vm.imageFile.size > 1024 * 2048)?_c('small',{staticClass:"text-danger"},[_vm._v(" Ukuran File Tidak Bisa Lebih dari 2 MB ")]):_vm._e()]):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Deskripsi Video","label-cols-md":"4"}},[_c('b-form-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":"Deskripsi Video","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('ckeditor',{attrs:{"editor":_vm.editor,"config":_vm.editorConfig,"state":errors.length > 0 ? false:null},model:{value:(_vm.descriptionVideo),callback:function ($$v) {_vm.descriptionVideo=$$v},expression:"descriptionVideo"}})]}}])})],1)],1)],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Video Pengantar","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Video Pengantar","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('b-form-input',{attrs:{"label":"title","state":errors.length > 0 ? false:null},model:{value:(_vm.videoPengantar),callback:function ($$v) {_vm.videoPengantar=$$v},expression:"videoPengantar"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Status Kelas","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Status Kelas","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.statusKelasOptions,"label":"title","searchable":false,"state":errors.length > 0 ? false:null},model:{value:(_vm.statusClass),callback:function ($$v) {_vm.statusClass=$$v},expression:"statusClass"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mt-2",attrs:{"md":"12"}},[_c('b-button',{staticClass:"mr-50",attrs:{"variant":"primary","type":"submit","disabled":_vm.loadingSubmit},on:{"click":function($event){$event.preventDefault();return _vm.submit($event)}}},[(_vm.loadingSubmit)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" Submit ")],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }